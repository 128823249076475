import {RoleEnum} from "../../components/authentication/RoleEnum";
import RestrictedAccess from "../../components/authentication/RestrictedAccess";
import QuestionBanksManagement from "./parts/QuestionBanksManagement";

export default function QuestionsBank() {

    return (
        <RestrictedAccess authorizedRoles={[RoleEnum.STAFF_GM_IS]}>
            <QuestionBanksManagement />
        </RestrictedAccess>
    );

}