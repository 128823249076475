import {RoleEnum} from "../../components/authentication/RoleEnum";
import RestrictedAccess from "../../components/authentication/RestrictedAccess";
import MyAssessmentsManagement from "./parts/MyAssessmentsManagement";

export default function MyAssessments() {
    return (
        <RestrictedAccess authorizedRoles={[RoleEnum.STAFF_GM_IS]}>
            <MyAssessmentsManagement />
        </RestrictedAccess>
    );
}