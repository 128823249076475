import axios, {AxiosResponse} from "axios";
import Subject from "../domain/Subject";

export function getDutyContent(subjectId: any): Promise<AxiosResponse<string>> {
    const url = window._env_.ASSESSMENTS_API_URL + `subjects/${subjectId}/duties`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}

export function getMyDutyContent(instanceCode: any): Promise<AxiosResponse<string>> {
    const url = window._env_.ASSESSMENTS_API_URL + `assessments/instances/${instanceCode}/duties`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}

export function getDutyResponse(instanceCode: any): Promise<AxiosResponse<string>> {
    const url = window._env_.ASSESSMENTS_API_URL + `assessments/instances/${instanceCode}/duties/responses`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}

export function updateDutyContent(subjectId: any, file: File): Promise<AxiosResponse<Subject>> {
    const formData = new FormData();
    formData.append("document", file);
    const url = window._env_.ASSESSMENTS_API_URL + `subjects/${subjectId}/duties`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.post(url, formData, config);
}

export function updateMyDutyResponse(instanceCode: any, file: File) {
    const formData = new FormData();
    formData.append("document", file);
    const url = window._env_.ASSESSMENTS_API_URL + `assessments/instances/${instanceCode}/duties/responses`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.post(url, formData, config);
}