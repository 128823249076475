import React from "react";
import {Dropdown, Footer, Sidebar} from "flowbite-react";
import {HiBeaker, HiChartPie, HiDocumentReport, HiUsers} from "react-icons/hi";
import {HiArrowRightOnRectangle, HiLanguage} from "react-icons/hi2";
import {MdOutlineQuiz} from "react-icons/md";
import AbstractDrawer from "../../AbstractDrawer";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAuthenticationContext} from "../../authentication/AuthenticationContext";
import AbstractDropdown from "../../AbstractDropdown";
import i18next from "i18next";
import ShowIfRoles from "../../ConditionalShow/ShowIfRoles";
import {RoleEnum} from "../../authentication/RoleEnum";
import DrawerCategoryRestricted from "./DrawerCategoryRestricted";
import DrawerItemRestricted from "./DrawerItemRestricted";

const langOptions = [
    {
        "code": "en",
        "title": "🇬🇧 English"
    },
    {
        "code": "fr",
        "title": "🇫🇷 Français"
    },
    {
        "code": "ar",
        "title": "🇸🇦 العربية"
    }
]

export default function Drawer({setDrawerOpen, isDrawerOpen}: {
    setDrawerOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void,
    isDrawerOpen: boolean
}) {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const {logout} = useAuthenticationContext();

    function getLanguage() {
        return i18next.language || window.localStorage.i18nextLng;
    }

    function getMenuOptions() {
        return langOptions.filter(opt => opt.code !== getLanguage())
            .map(opt => (
                <Dropdown.Item key={opt.code} onClick={() => i18n.changeLanguage(opt.code)}>
                    <p className="text-base">{opt.title}</p>
                </Dropdown.Item>
            ))
    }

    function goTo(link: string) {
        setDrawerOpen(false);
        navigate(link);
    }

    return (
        <AbstractDrawer side="start" setIsOpen={setDrawerOpen} isOpen={isDrawerOpen}>
            <img
                src="/logo-yellow.svg"
                className="mt-2 h-6 sm:h-16"
                alt="École Hexagone logo"
            />
            <Footer.Divider/>
            <div className="flex w-full h-full">
                <Sidebar className="w-full h-full" aria-label="MyHexagone sidebar">
                    <Sidebar.Items>
                        <Sidebar.ItemGroup>
                            <Sidebar.Item
                                onClick={() => goTo("/dashboard")}
                                className="hover:cursor-pointer"
                                icon={HiChartPie}
                            >
                                {t("menu.dashboard")}
                            </Sidebar.Item>
                            <DrawerItemRestricted
                                linkTo={"my/assessments"} icon={MdOutlineQuiz}
                                onClick={goTo} label="my.assessments"
                                restrictedTo={[RoleEnum.STUDENT, RoleEnum.STAFF_GM_IS]} />
                        </Sidebar.ItemGroup>
                        <ShowIfRoles restrictedTo={[RoleEnum.STAFF, RoleEnum.TEACHER]}>
                            <Sidebar.ItemGroup>
                                <DrawerCategoryRestricted icon={HiBeaker}
                                                          label="enrolmentTests"
                                                          restrictedTo={[RoleEnum.STAFF_GM_IS]}>
                                    <DrawerItemRestricted linkTo={"enrolment-tests/questions-bank"}
                                                          onClick={goTo} label="enrolmentTests.questionsBank"
                                                          restrictedTo={[RoleEnum.STAFF_GM_IS]} />
                                    <DrawerItemRestricted linkTo={"enrolment-tests/quizzes"}
                                                          onClick={goTo}
                                                          label="enrolmentTests.quizzes"
                                                          restrictedTo={[RoleEnum.STAFF_GM_IS]} />
                                </DrawerCategoryRestricted>
                                <DrawerItemRestricted icon={HiUsers}
                                                      linkTo={"users"}
                                                      onClick={goTo}
                                                      label="users"
                                                      restrictedTo={[RoleEnum.STAFF_GM_IS,
                                                          RoleEnum.STAFF_GM_ACADEMIC,
                                                          RoleEnum.CM_HEAD,
                                                          RoleEnum.STAFF_GM_QUALITY,
                                                          RoleEnum.STAFF_GM_MANAGEMENT]} />
                                <DrawerCategoryRestricted icon={HiDocumentReport}
                                                          label="assessments"
                                                          restrictedTo={[RoleEnum.STAFF_GM_IS]}>
                                    <DrawerItemRestricted linkTo={"assessments/subjects"}
                                                          onClick={goTo}
                                                          label="assessments.subjects"
                                                          restrictedTo={[RoleEnum.STAFF_GM_IS]} />
                                    <DrawerItemRestricted linkTo={"assessments/questions-bank"}
                                                          onClick={goTo}
                                                          label="assessments.questionsBank"
                                                          restrictedTo={[RoleEnum.STAFF_GM_IS]} />
                                    <DrawerItemRestricted linkTo={"assessments"}
                                                          onClick={goTo}
                                                          label="assessments.assessments"
                                                          restrictedTo={[RoleEnum.STAFF_GM_IS]} />
                                </DrawerCategoryRestricted>
                            </Sidebar.ItemGroup>
                        </ShowIfRoles>
                        <Sidebar.ItemGroup>
                            <AbstractDropdown
                                renderTrigger={() =>
                                    <Sidebar.Item icon={HiLanguage}
                                                  className="hover:cursor-pointer">
                                        {t("menu.switchLang")}
                                    </Sidebar.Item>}
                                content={getMenuOptions()}
                            />
                            <Sidebar.Item
                                icon={HiArrowRightOnRectangle}
                                className="hover:cursor-pointer"
                                onClick={() => logout()}
                            >
                                {t("logout")}
                            </Sidebar.Item>
                        </Sidebar.ItemGroup>
                    </Sidebar.Items>
                </Sidebar>
            </div>
        </AbstractDrawer>
    );
}

