import {RoleEnum} from "../../components/authentication/RoleEnum";
import RestrictedAccess from "../../components/authentication/RestrictedAccess";
import AssessmentsManagement from "./parts/AssessmentsManagement";

export default function Assessments() {
    return (
        <RestrictedAccess authorizedRoles={[RoleEnum.STAFF_GM_IS]}>
            <AssessmentsManagement />
        </RestrictedAccess>
    );
}