import RestrictedAccess from "../../components/authentication/RestrictedAccess";
import {RoleEnum} from "../../components/authentication/RoleEnum";
import SubjectsManagement from "./parts/SubjectsManagement";

export default function Subjects() {

    return (
        <RestrictedAccess authorizedRoles={[RoleEnum.STAFF_GM_IS]}>
            <SubjectsManagement />
        </RestrictedAccess>
    );

}